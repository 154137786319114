import React, { Component } from 'react';
import { validaAutenticaocao, getCodUsuario, versao } from './servicos/autenticacao';
import PubSub from 'pubsub-js';

import Login from './componentes/login/login';
import Cabecalho from './componentes/cabecalho/cabecalho.js';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      logado: false
    };
  }
  
  componentDidMount(){
    PubSub.subscribe('logado', function(topic,data){
      this.setState({logado: data.logado});
    }.bind(this));
  }
  
  // Registra no banco de dados toda vez que a tela principal é carregada
  registrarAcesso() {
    let request = require('request');
    let options = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/prodbeer/acesso/acesso',
      headers: {
        'content-type': 'application/json'
      },
      body: {"codUsuario": getCodUsuario(), plataforma:"prodbeer", codigo_controle_versao: versao},
      json: true
    };
    request(options, function (error, response, body) {
      // verificar se o acess foi realizado com sucesso...
    }.bind(this));
  }
  
  render() { 
    let obj;
    if (validaAutenticaocao()) {
      this.registrarAcesso();
      obj = <Cabecalho />
    } else {
      obj = <Login />
    }
    return(
      obj
    );
  }
}

export default App;
