import React, { Component } from 'react';
import { Grid, Header, Icon, Button, Label, Loader, Segment } from 'semantic-ui-react'
import PubSub from 'pubsub-js';
import Tabela from '../tabela/tabela.js';
import { getToken, removeToken } from '../../servicos/autenticacao';

class ConsultaProducao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataExibicao: '',
      ArrayProducaoDia: [],
      loader: false
    }
    this.voltarTelaAnterior = this.voltarTelaAnterior.bind(this);
    this.alteraDataExibicao = this.alteraDataExibicao.bind(this);
    this.atualizaTabela = this.atualizaTabela.bind(this);
    this.atualizaTabelaPordata = this.atualizaTabelaPordata.bind(this);
  }
  
  componentDidMount(){
    let dataAtual = new Date(),
        dataAtualFormatada = dataAtual.getDate() +'/'+ (dataAtual.getMonth()+1)+'/' +dataAtual.getFullYear(),
        dia = dataAtual.getDate(),
        diaF = dia.toString().length > 1 ? dia : "0"+dia,
        mes = dataAtual.getMonth()+1,
        mesF = mes.toString().length > 1 ? mes : "0"+mes;
    let dataConsulta = dataAtual.getFullYear() + '-' + mesF + '-' + diaF;
    this.atualizaTabelaPordata(dataConsulta);
    this.setState({ dataExibicao: dataAtualFormatada });
  }
  
  voltarTelaAnterior(){
    PubSub.publish('opcComponente', {opcComponente:'LinhasProducao'});
  }
  
  alteraDataExibicao(opcaoClick) {
    let dataAtual = new Date(), 
        dataLimite = new Date(),
        dia = dataLimite.getDate(),
        diaAtual = dataAtual.getDate();
    dataLimite.setDate(dia - 5);
    dataAtual.setDate(diaAtual - 1);
    let dataCorrente = this.state.dataExibicao,
        ArrayDataCorrente = dataCorrente.split('/'),
        dataCorrenteFormatada = new Date(ArrayDataCorrente[2], ArrayDataCorrente[1] -1, ArrayDataCorrente[0]);
    if (opcaoClick === 'diminuir') {
      let diaCorrente = dataCorrenteFormatada.getDate();
      dataCorrenteFormatada.setDate(diaCorrente -1);
      let dataExibicaoFormatada = dataCorrenteFormatada.getDate() + '/' +
            (dataCorrenteFormatada.getMonth()+1)+'/'+dataCorrenteFormatada.getFullYear();
      let formataDataConsulta = dataCorrenteFormatada;
      formataDataConsulta.setDate(dataCorrenteFormatada.getDate());
      let dataFormatadaConsulta = formataDataConsulta.toISOString().substr(0, 10);
      this.setState({dataExibicao: dataExibicaoFormatada});
      this.atualizaTabelaPordata(dataFormatadaConsulta);
    }  else {
    }
    if (opcaoClick === 'adicionar') {
      let diaCorrente = dataCorrenteFormatada.getDate();
      dataCorrenteFormatada.setDate(diaCorrente +1);
      let dataExibicaoFormatada = dataCorrenteFormatada.getDate() + '/' +
            (dataCorrenteFormatada.getMonth()+1)+'/'+dataCorrenteFormatada.getFullYear();
      let formataDataConsulta = dataCorrenteFormatada;
      formataDataConsulta.setDate(dataCorrenteFormatada.getDate());
      let dataFormatadaConsulta = formataDataConsulta.toISOString().substr(0, 10);
      this.setState({dataExibicao: dataExibicaoFormatada});
      this.atualizaTabelaPordata(dataFormatadaConsulta);
    } else {
    }
  }
  
  atualizaTabela() {
    let dataAtual = new Date(),
        dia = dataAtual.getDate(),
        diaF = dia.toString().length > 1 ? dia : "0"+dia,
        mes = dataAtual.getMonth()+1,
        mesF = mes.toString().length > 1 ? mes : "0"+mes
    let dataAtualFormtada = dataAtual.getFullYear() + '-' + mesF + '-' + diaF;
    this.atualizaTabelaPordata(dataAtualFormtada);
    let data_formatada = dataAtual.getDate() +'/'+ (dataAtual.getMonth()+1)+'/'+dataAtual.getFullYear();
    this.setState({dataExibicao: data_formatada});
  }
  
  atualizaTabelaPordata(dataCorrente) {
    this.setState({loader: true});
    //Carregar produção.
    let request = require('request');
    const opcoesProducao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoesPorData',
      headers : {
        'Authorization': getToken()
      },
      body: {"data": dataCorrente},
      json: true
    };
    request(opcoesProducao, function (error, response, body) {
      if (body !== undefined) {
        if (body.Producao) {
          this.setState({ ArrayProducaoDia:body.Producao, loader: false });
        } else if(body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
      } else {
        this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", ArrayProducaoDia:[], loader: false });
      }
      
    }.bind(this));
  }
  
  render() {
    return(
      <div>
        <Grid textAlign = 'center' style = {{ height: '100%' }} verticalAlign = 'top'>
          <Grid.Row style = {{ padding: '0px', width:'350px' }}>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'50px' }}></Grid.Column>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'250px'}}>
              <Header as = 'h5' style = {{ marginTop: '0px', textAlign: 'center' }}>
                { this.props.nomeFabrica } <br /> { this.props.nomeLinha }
              </Header>
            </Grid.Column>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'50px' }}>
              <Icon name = 'refresh' style = {{ paddingRight:'25px' }} onClick = { this.atualizaTabela } />
              <Icon name = 'window close' onClick = { this.voltarTelaAnterior }/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{ width:'350px', height: '65px', padding: '0px' }}>
            <Grid.Column style={{textAlign: 'left', paddingLeft:'0px', paddingRight:'0px', width:'116px'}}>
              <Segment basic>
                <Button icon onClick = { this.alteraDataExibicao.bind(this, 'diminuir') }>
                  <Icon name='angle left' attached='left'/>
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{ textAlign: 'center', paddingLeft:'0px',paddingRight:'0px', width:'116px'}}>
              <Segment basic >
                <Label size="big" style={{height:'40px', fontSize:'11px', padding:'15px'}}>
                  {this.state.dataExibicao}
                </Label>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{textAlign: 'right', paddingLeft:'0px',paddingRight:'0px', width:'116px'}}>
              <Segment basic>
                <Button icon onClick = { this.alteraDataExibicao.bind(this, 'adicionar') }>
                  <Icon name='angle right' />
                </Button>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{ textAlign: 'center', padding:'0px', fontSize:'12px', width:'350px' }}>
            <Tabela nomeFabrica = {this.props.nomeFabrica} nomeLinha = {this.props.nomeLinha}
                    ArrayProducaoDia = {this.state.ArrayProducaoDia} ArrayFiliais = { this.props.ArrayFiliais } 
                    ArrayLinhasProducao = { this.props.ArrayLinhasProducao } ArrayProdutos = {this.props.ArrayProdutos} 
                    ArrayProdutosLinha = {this.props.ArrayProdutosLinha} />
          </Grid.Row>
        </Grid>
        {this.state.loader && <Loader active />}
      </div>
    );
  }
}

export default ConsultaProducao