import React, { Component } from 'react';
import { Grid, Header, Icon, Loader, Message} from 'semantic-ui-react';
import { ReactTabulator } from 'react-tabulator';
import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css";
import { getToken, removeToken, versao, getCodUsuario } from '../../servicos/autenticacao';
import PubSub from 'pubsub-js';

class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loader: false,
      mensagem: ''
    };
    //this.voltarTelaAnterior = this.voltarTelaAnterior(this);
    this.rowClick = this.rowClick.bind(this);
  }
  
  componentDidMount(){
    this.setState({loader: true});
    const request = require('request');
    const postRelatorioPlanejadoRealizado = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/relatorio/planejadorealizado',
      headers : {
        'Authorization': 'getToken()'
      },
      json: true
    }
    request(postRelatorioPlanejadoRealizado, function (error, response, body) {
      if (body !== undefined) {
        if (body.data.length > 0) {
          this.setState({
            data: body.data, loader: false
          })
        } else {
          this.setState({loader: false});
        }
      } else {
        this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", exibeMensagem: true, loader: false, });
      }
    }.bind(this));
  }
  
  voltarTelaAnterior(){
    PubSub.publish('opcComponente', {opcComponente:''});
  }
  
  rowClick(e, row){
    const request = require('request');
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '/' + mesF + '/' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    const token = Math.random() + '-' + horaF + '-' + Math.random() + '-' + 
                  diaF + '-' + Math.random() + '-' + minutosF + '-' + Math.random() + '-' + 
                  anoF + '-' + Math.random() + '-' + mesF;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os;
    const postRegistraDownload = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/prodbeer/relatorio/registradownload',
      headers: {
        'content-type': 'application/json',
        'Authorization': getToken()
      },
      body: { 
        "data": dataFormatada, "hora": horaFormatada, "nomeArquivo": row._row.data.caminhoArquivo, 
        "codUsuario": getCodUsuario(), "dispositivo": browser.os, "codigoControleVersao": codigoVersao
      },
      json: true
    };
    request(postRegistraDownload, function (error, response, body) {
      if (body.Acao !== undefined) {
        if (browser.name === 'ios') {
          window.location = "googlechrome-x-callback://inteligenciaindustrial.com/prodbeer/relatorio/download/" + row._row.data.key + "&" + token + "&" + getToken() +"&" + row._row.data.caminhoArquivo;
        } else {
          window.location = "https://inteligenciaindustrial.com/prodbeer/relatorio/download/" + row._row.data.key + "&" + token + "&" + getToken() +"&" + row._row.data.caminhoArquivo;
        }
      } else {
        this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", exibeMensagem: true, loader: false, });
      }
    })
  }
  
  render() {
    const columns = [
      { 
        title: "ARQUIVO", 
        field: "nomeArquivo",
        align: "left",
        minWidth : 120,
        responsive:0
      }
    ];
    let Options = {};
    Options = {responsiveLayout:true, groupBy:["ano"], groupStartOpen:function(value, count, data, group){
      let anoAtual = new Date();
      anoAtual = anoAtual.getFullYear();
      return parseInt(anoAtual) === parseInt(value) ? true : false; //all groups with more than three rows start open, any with three or less start closed
    }}
    return(
      <div>
        <Grid textAlign = 'center' style = {{ height: '100%' }} verticalAlign = 'top'>
          <Grid.Row style = {{ padding: '0px', width:'350px' }}>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'50px' }}></Grid.Column>
            <Grid.Column style = {{ paddingTop: '10px', paddingLeft:'0px',paddingRight:'0px', width:'250px'}}>
              <Header as = 'h5' style = {{ marginTop: '0px', textAlign: 'center' }}>
                { this.props.tituloTela }
              </Header>
            </Grid.Column>
            <Grid.Column style = {{ paddingTop: '8px', paddingLeft:'0px',paddingRight:'0px', width:'50px' }}>
              <Icon name = 'window close' style = {{ paddingRight:'25px' }} onClick = { this.voltarTelaAnterior } />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        { this.state.exibeMensagem  &&
            <Message style = {{ textAlign: 'center', width:'350px', fontSize: '14px' }}>
              {this.state.mensagem}
            </Message>
        }
        <ReactTabulator
              data={this.state.data}
              style={{textAlign: 'left', marginTop:'40px', marginLeft: 'auto', marginRight:'auto', fontSize:'11px', maxWidth: '350px', height: '480px'}}
              className={'very compact celled'}
              options= {Options}
              columns={columns}
              tooltips={true}
              layout={"fitColumns"}
              rowClick={this.rowClick}
        />
        {this.state.loader && <Loader active />}
      </div>
    );
  }
}

export default Relatorio