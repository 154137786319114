import React, { Component } from 'react';
import { Grid, Header, Icon, Segment, Button, Label, Card, Table, Loader, Message } from 'semantic-ui-react'
import PubSub from 'pubsub-js';
import { getToken, removeToken } from '../../servicos/autenticacao';

class Consolidado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataExibicao: '',
      ArrayProducaoDia: [],
      ConteudoTabela: [],
      AcumuladoHl: 0,
      AcumuladoPcCx: 0,
      loader: false,
      mensagem: '',
      exibeMensagem: false
    }
    this.voltarTelaAnterior = this.voltarTelaAnterior.bind(this);
    this.atualizaDados = this.atualizaDados.bind(this);
    this.atualizaParaDataAtual = this.atualizaParaDataAtual.bind(this);
  }
  
  //
  componentDidMount() {
    //console.log(this.props)    
    let dataAtual = new Date(),
        dataAtualFormatada = dataAtual.getDate() +'/'+ (dataAtual.getMonth()+1)+'/' +dataAtual.getFullYear(),
        dia = dataAtual.getDate(),
        diaF = dia.toString().length > 1 ? dia : "0"+dia,
        mes = dataAtual.getMonth()+1,
        mesF = mes.toString().length > 1 ? mes : "0"+mes;
    let dataConsulta = dataAtual.getFullYear() + '-' + mesF + '-' + diaF;
    this.setState({dataExibicao: dataAtualFormatada, loader: true});
    //console.log(dataConsulta);
    //Carregar produção.
    let request = require('request');
    const opcoesProducao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoesPorData',
      headers : {
        'Authorization': getToken()
      },
      body: {"data": dataConsulta},
      json: true
    };
    request(opcoesProducao, function (error, response, body) {
      if (body !== undefined) {
        if (body.Producao) {
          this.montaTabela(body.Producao);
          this.setState({ ArrayProducaoDia:body.Producao, dataExibicao: dataAtualFormatada, exibeMensagem: false });
        } else if(body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
      } else {
        this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", ConteudoTabela:[], 
                        dataExibicao: dataAtualFormatada, exibeMensagem: true, loader: false, });
      }
      
    }.bind(this));
  }
  //
  voltarTelaAnterior(){
    if (this.props.opcaoTela === "Linhas") {
      PubSub.publish('opcComponente', {opcComponente:'LinhasProducao'});
    } else {
      PubSub.publish('opcComponente', {opcComponente:''});
    }
  }
  //
  alteraDataExibicao(opcaoClick) {
    let dataAtual = new Date(), 
        dataLimite = new Date(),
        dia = dataLimite.getDate(),
        diaAtual = dataAtual.getDate();
    dataLimite.setDate(dia - 5);
    dataAtual.setDate(diaAtual - 1);
    let dataCorrente = this.state.dataExibicao,
        ArrayDataCorrente = dataCorrente.split('/'),
        dataCorrenteFormatada = new Date(ArrayDataCorrente[2], ArrayDataCorrente[1] -1, ArrayDataCorrente[0]);
    if (opcaoClick === 'diminuir') {
      this.setState({loader: true});
      let diaCorrente = dataCorrenteFormatada.getDate();
      dataCorrenteFormatada.setDate(diaCorrente -1);
      let dataExibicaoFormatada = dataCorrenteFormatada.getDate() + '/' +
            (dataCorrenteFormatada.getMonth()+1)+'/'+dataCorrenteFormatada.getFullYear();
      let formataDataConsulta = dataCorrenteFormatada;
      formataDataConsulta.setDate(dataCorrenteFormatada.getDate());
      let dataFormatadaConsulta = formataDataConsulta.toISOString().substr(0, 10);
      this.atualizaDados(dataFormatadaConsulta, dataExibicaoFormatada);
      //this.setState({dataExibicao: dataExibicaoFormatada});
    }
    if (opcaoClick === 'adicionar') {
      this.setState({loader: true});
      let diaCorrente = dataCorrenteFormatada.getDate();
      dataCorrenteFormatada.setDate(diaCorrente +1);
      let dataExibicaoFormatada = dataCorrenteFormatada.getDate() + '/' +
            (dataCorrenteFormatada.getMonth()+1)+'/'+dataCorrenteFormatada.getFullYear();
      let formataDataConsulta = dataCorrenteFormatada;
      formataDataConsulta.setDate(dataCorrenteFormatada.getDate());
      let dataFormatadaConsulta = formataDataConsulta.toISOString().substr(0, 10);
      this.atualizaDados(dataFormatadaConsulta, dataExibicaoFormatada);
    }
  }
  //
  atualizaParaDataAtual() {
    this.setState({loader: true});
    let dataAtual = new Date(),
        dataAtualFormatada = dataAtual.getDate() +'/'+ (dataAtual.getMonth()+1)+'/' +dataAtual.getFullYear(),
        dia = dataAtual.getDate(),
        diaF = dia.toString().length > 1 ? dia : "0"+dia,
        mes = dataAtual.getMonth()+1,
        mesF = mes.toString().length > 1 ? mes : "0"+mes;
    let dataConsulta = dataAtual.getFullYear() + '-' + mesF + '-' + diaF;
    //Carregar produção.
    let request = require('request');
    const opcoesProducao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoesPorData',
      headers : {
        'Authorization': getToken()
      },
      body: {"data": dataConsulta},
      json: true
    };
    request(opcoesProducao, function (error, response, body) {
      if (body !== undefined) {
        if (body.Producao) {
          this.montaTabela(body.Producao);
          this.setState({ ArrayProducaoDia:body.Producao, dataExibicao: dataAtualFormatada, exibeMensagem: false });
        } else if(body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
      } else {
        this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", ConteudoTabela:[], 
                        dataExibicao: dataAtualFormatada, exibeMensagem: true, loader: false, });
      }
      
    }.bind(this));
  }
  //
  atualizaDados(dataConsulta, dataExibicao){
    //Carregar produção.
    let request = require('request');
    const opcoesProducao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoesPorData',
      headers : {
        'Authorization': getToken()
      },
      body: {"data": dataConsulta},
      json: true
    };
    request(opcoesProducao, function (error, response, body) {
      if (body !== undefined) {
        if (body.Producao) {
          this.montaTabela(body.Producao);
          this.setState({ ArrayProducaoDia:body.Producao, dataExibicao: dataExibicao, exibeMensagem: false });
        } else if(body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
      } else {
        this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", ConteudoTabela:[], 
                        dataExibicao: dataExibicao, exibeMensagem: true, loader: false, });
      }
      
    }.bind(this));
  }
  //
  montaTabela(ArrayProducaoDia){
    this.setState({loader: true});
    let date = new Date(), 
        hora = date.getHours(),
        horaF = hora.toString().length > 1 ? hora : "0"+hora,
        dia = date.getDate(),
        diaF = dia.toString().length > 1 ? dia : "0"+dia,
        mes = date.getMonth()+1,
        mesF = mes.toString().length > 1 ? mes : "0"+mes;
    let dataConsulta = date.getFullYear() + '-' + mesF + '-' + diaF;
    //console.log(hora);
    let ConteudoTabela = [], ArrayHoras = [], acumuladoHl = 0, acumuladoPcCx = 0;
    if (this.props.opcaoTela === 'Fabricas') {
      for (let index = 0; index < ArrayProducaoDia.length; index++) {
        if (dataConsulta === ArrayProducaoDia[index].DATA) {
          if (ArrayProducaoDia[index].D_E_L_E_T !== '*' && ArrayProducaoDia[index].PRODUCAO !== '' &&
              ArrayProducaoDia[index].PRODUCAO !== null && parseInt(ArrayProducaoDia[index].HORA) <= parseInt(horaF)) {
            ArrayHoras.push(ArrayProducaoDia[index].HORA)
          }
        } else {
          if (ArrayProducaoDia[index].D_E_L_E_T !== '*' && ArrayProducaoDia[index].PRODUCAO !== '' &&
              ArrayProducaoDia[index].PRODUCAO !== null ) {
            ArrayHoras.push(ArrayProducaoDia[index].HORA)
          }
        }
      }
    } else if(this.props.opcaoTela === 'Linhas') {
      const Filial = this.props.ArrayFiliais.filter(filial => {
        return(filial.DESC_FILIAL === this.props.nomeFabrica)
      });
      for (let index = 0; index < ArrayProducaoDia.length; index++) {
        if (dataConsulta === ArrayProducaoDia[index].DATA) {
          if (ArrayProducaoDia[index].D_E_L_E_T !== '*' && ArrayProducaoDia[index].PRODUCAO !== '' &&
              ArrayProducaoDia[index].PRODUCAO !== null && ArrayProducaoDia[index].COD_FILIAL === Filial[0].COD_FILIAL && 
              parseInt(ArrayProducaoDia[index].HORA) <= parseInt(horaF)) {
            ArrayHoras.push(ArrayProducaoDia[index].HORA)
          }
        } else {
          if (ArrayProducaoDia[index].D_E_L_E_T !== '*' && ArrayProducaoDia[index].PRODUCAO !== '' &&
              ArrayProducaoDia[index].PRODUCAO !== null && ArrayProducaoDia[index].COD_FILIAL === Filial[0].COD_FILIAL) {
            ArrayHoras.push(ArrayProducaoDia[index].HORA)
          }
        }
      }
    }
    ArrayHoras.reverse();
    //remove os registros duplicados
    let ArrayHorasFiltrado = ArrayHoras.filter(function(este, i) {
      return ArrayHoras.indexOf(este) === i;
    });
    for (var index = 0; index < ArrayHorasFiltrado.length; index++) {
      let valorProducaoGrLt = 0, valorProducaoCxPc = 0, valorProducaoHl = 0
      let Somatorios = [];
      if (this.props.opcaoTela === 'Fabricas') {
        Somatorios = ArrayProducaoDia.filter(producao => {
          if(producao.HORA === ArrayHorasFiltrado[index]){
            valorProducaoGrLt += this.exibeProducaoFormatada(producao.PRODUCAO, producao.COD_PRODUTO, 'lt/gfa');
            valorProducaoCxPc += this.exibeProducaoFormatada(producao.PRODUCAO, producao.COD_PRODUTO, 'cx/pc');
            valorProducaoHl += this.exibeProducaoFormatada(producao.PRODUCAO, producao.COD_PRODUTO, 'hl');
          }
        });
      } else if(this.props.opcaoTela === 'Linhas') {
        const Filial = this.props.ArrayFiliais.filter(filial => {
          return(filial.DESC_FILIAL === this.props.nomeFabrica)
        });
        Somatorios = ArrayProducaoDia.filter(producao => {
          if(producao.HORA === ArrayHorasFiltrado[index] && producao.COD_FILIAL === Filial[0].COD_FILIAL){
            valorProducaoGrLt += this.exibeProducaoFormatada(producao.PRODUCAO, producao.COD_PRODUTO, 'lt/gfa');
            valorProducaoCxPc += this.exibeProducaoFormatada(producao.PRODUCAO, producao.COD_PRODUTO, 'cx/pc');
            valorProducaoHl += this.exibeProducaoFormatada(producao.PRODUCAO, producao.COD_PRODUTO, 'hl');
          }
        });
      }
      acumuladoHl += valorProducaoHl;
      acumuladoPcCx += valorProducaoCxPc;
      ConteudoTabela.push(
        <Table.Row key = {index}>
          <Table.Cell style = {{ width:'45px', padding:'5px', textAlign: 'center' }}> 
            {ArrayHorasFiltrado[index]}
          </Table.Cell>
          <Table.Cell style = {{ width:'110px', padding:'5px', textAlign: 'center'}}>
            {this.defineFormatacao(valorProducaoGrLt, 'lt/gfa')}
          </Table.Cell>
          <Table.Cell style = {{ width:'110px', padding:'5px', textAlign: 'center' }}>
            {this.defineFormatacao(valorProducaoCxPc, 'cx/pc')}
          </Table.Cell>
          <Table.Cell style = {{ width:'75px', padding:'5px', textAlign: 'center'}} >
            {this.defineFormatacao(valorProducaoHl, 'hl')}
          </Table.Cell>
            <Table.Cell style = {{ width:'10px', padding:'5px', textAlign: 'center' }}>
          </Table.Cell>
        </Table.Row>
      )
    }
    this.setState({
      ConteudoTabela: ConteudoTabela, AcumuladoHl: acumuladoHl, AcumuladoPcCx: acumuladoPcCx, loader: false 
    });
    //console.log(ConteudoTabela)
  }
  //
  exibeProducaoFormatada(producao, codProduto, unidade) {
    const Produto = this.props.ArrayProdutos.filter((produto) => {
      return produto.COD_PRODUTO === codProduto;
    });
    if (unidade === "lt/gfa") {
      return parseInt(producao);
    }
    if (unidade === "hl") {
      return Produto[0].FATOR_CONVERSAO * producao;
    }
    if (unidade === "cx/pc") {
      if (Produto[0].QUANTIDADE_UNIDADE !== "0") {
        return producao / Produto[0].QUANTIDADE_UNIDADE;
      } else {
        return 0;
      }
    }
  }
  //
  defineFormatacao(valorProducao, unidade) {
    const formatDecimal = require('format-decimal');
    const opts =  {
      decimal   : ',',
      precision : 0,
      thousands : '.'
    };
    const optsDecimal =  {
      decimal   : ',',
      precision : 2,
      thousands : '.'
    };
    
    if (unidade === "lt/gfa" || unidade === "cx/pc") {
      return formatDecimal(valorProducao, opts);
    }
    if (unidade === "hl") {
      return formatDecimal(valorProducao, optsDecimal);
    }
  }
  //
  render() {
    return(
      <div>
        <Grid textAlign = 'center' style = {{ height: '100%' }} verticalAlign = 'top'>
          <Grid.Row style = {{ padding: '0px', width:'350px' }}>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'50px' }}></Grid.Column>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'250px'}}>
              <Header as = 'h5' style = {{ marginTop: '0px', textAlign: 'center' }}>
                { this.props.nomeFabrica } <br /> { this.props.tituloTela }
              </Header>
            </Grid.Column>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'50px' }}>
              <Icon name = 'refresh' style = {{ paddingRight:'25px' }} onClick = { this.atualizaParaDataAtual } />
              <Icon name = 'window close' onClick = { this.voltarTelaAnterior }/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{ width:'350px', height: '65px', padding: '0px' }}>
            <Grid.Column style={{textAlign: 'left', paddingLeft:'0px', paddingRight:'0px', width:'116px'}}>
              <Segment basic>
                <Button icon onClick = { this.alteraDataExibicao.bind(this, 'diminuir') }>
                  <Icon name='angle left' attached='left'/>
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{ textAlign: 'center', paddingLeft:'0px',paddingRight:'0px', width:'116px'}}>
              <Segment basic >
                <Label size="big" style={{height:'40px', fontSize:'11px', padding:'15px'}}>
                  {this.state.dataExibicao}
                </Label>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{textAlign: 'right', paddingLeft:'0px',paddingRight:'0px', width:'116px'}}>
              <Segment basic>
                <Button icon onClick = { this.alteraDataExibicao.bind(this, 'adicionar') }>
                  <Icon name='angle right' />
                </Button>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{ textAlign: 'center', padding:'0px', fontSize:'12px', width:'350px' }}>
            <Grid columns ='equal'>
              <Grid.Column style={{paddingLeft:'0px',paddingRight:'5px', width:'175px'}}>
                <Card style={{height: '75px'}}>
                  <Card.Content>
                    <Card.Header style={{marginTop: '0px'}}>HECTOLITROS</Card.Header>
                    <Card.Meta style={{marginTop: '0px'}}>Acumulado</Card.Meta>
                    <Card.Description style={{fontSize:'15px', fontWeight: 'bold', marginTop: '0px'}}>{this.defineFormatacao(this.state.AcumuladoHl, 'hl')}</Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column style={{paddingLeft:'5px',paddingRight:'0px', width:'175px'}}>
                <Card style={{height: '75px'}}>
                  <Card.Content>
                    <Card.Header style={{marginTop: '0px'}}>PACOTES/CAIXAS</Card.Header>
                    <Card.Meta style={{marginTop: '0px'}}>Acumulado</Card.Meta>
                    <Card.Description style={{fontSize:'15px', fontWeight: 'bold', marginTop: '0px'}}>{this.defineFormatacao(this.state.AcumuladoPcCx, 'cx/pc')}</Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid>
          </Grid.Row>
          { this.state.ArrayProducaoDia.length === 0 && this.state.exibeMensagem === false &&
              <Message style = {{ textAlign: 'center', width:'350px', fontSize: '14px' }}>
                Sem dados lançados para este dia
              </Message>
          }
          { this.state.exibeMensagem  &&
              <Message style = {{ textAlign: 'center', width:'350px', fontSize: '14px' }}>
                {this.state.mensagem}
              </Message>
          }
          { this.state.ConteudoTabela.length > 0 &&
          <Grid.Row >
            <Table className="ui striped table" unstackable style={{ padding:'0px', width:'350px'}} >
              <Table.Header style={{display: 'block', fontSize:'12px',  width:'350px'}}>
                <Table.Row style = {{ textAlign: 'center',  width:'350px' }}>
                  <Table.HeaderCell style = {{ height: '45px', width:'45px', padding:'0px', textAlign: 'center' }}> 
                    Hora 
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'110px', padding:'0px', textAlign: 'center'}}>
                    Grf/Lt
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'110px', padding:'0px', textAlign: 'center' }}>
                    Pct/Cx
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'75px', padding:'0px', textAlign: 'center'}} >
                    HL
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'10px', padding:'0px', textAlign: 'center' }}>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style = {{ fontSize:'10px', display: 'block', height: '360px', width:'350px', overflow: 'auto'}}>
                {this.state.ConteudoTabela}
              </Table.Body>
            </Table>
          </Grid.Row>
          }
        </Grid>
        {this.state.loader && <Loader active />}
      </div>
    )
  }
}
export default Consolidado