import React, { Component } from 'react';
import { Grid, Dropdown, Table, Message, Icon, Modal, Container, Button, Card} from 'semantic-ui-react'
import PubSub from 'pubsub-js';
import { getToken, removeToken } from '../../servicos/autenticacao';
//import Card from '../geral/card.js';

class ConsultaProducao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exibeProducaoPor: 'cx/pc',
      mensagem: '',
      nomeFabrica:'',
      nomeLinha: '',
      ArrayProducaoDia: [],
      mensagemModalObservacoes: '',
      exibeModalObservacoes: false
    }
    this.alteraExibicaoTabela = this.alteraExibicaoTabela.bind(this);
  }
  
  componentDidMount(){
    let dataAtual = new Date(),
        dataAtualFormatadaConsulta = dataAtual.getFullYear()+'-'+dataAtual.getMonth()+1+'-'+dataAtual.getDate();
    //Carregar produção.
    let request = require('request');
    const opcoesProducao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoesPorData',
      headers : {
        'Authorization': getToken()
      },
      body: {"data": dataAtualFormatadaConsulta},
      json: true
    };
    request(opcoesProducao, function (error, response, body) {
      if (body !== undefined) {
        if (body.Producao) {
          this.setState({ ArrayProducaoDia:body.Producao });
        } else if(body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
      } else {
        this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", ArrayProducaoDia:[] });
      }
      
    }.bind(this));
  }
  
  alteraExibicaoTabela(event, data){
    this.setState({exibeProducaoPor: data.value})
  }
  
  buscaNomeProduto(codProduto) {
    const Produto = this.props.ArrayProdutos.filter((produto) => {
      return produto.COD_PRODUTO === codProduto
    });
    return Produto[0].DESC_PRODUTO
  }
  
  exibeProducaoFormatada(producao, codProduto) {
    const formatDecimal = require('format-decimal');
    const opts =  {
      decimal   : ',',
      precision : 0,
      thousands : '.'
    };
    const optsDecimal =  {
      decimal   : ',',
      precision : 2,
      thousands : '.'
    };
    const Produto = this.props.ArrayProdutos.filter((produto) => {
      return produto.COD_PRODUTO === codProduto;
    });
    const { exibeProducaoPor } = this.state;
    if (exibeProducaoPor === "lt/gfa") {
      return formatDecimal(parseInt(producao),opts);
    }
    if (exibeProducaoPor === "hl") {
      return formatDecimal(Produto[0].FATOR_CONVERSAO * producao,optsDecimal);
    }
    if (exibeProducaoPor === "cx/pc") {
      if (Produto[0].QUANTIDADE_UNIDADE !== "0") {
        return formatDecimal(producao / Produto[0].QUANTIDADE_UNIDADE, opts);
      } else {
        return 0;
      }
    }
  }
  
  exibeProducaoAcumulada(ArrayProducaoDiaFiltrado) {
    const { exibeProducaoPor } = this.state;
    let ArrayAcumulado = [];
    if (exibeProducaoPor === "lt/gfa") {
      for (let index = 0; index < ArrayProducaoDiaFiltrado.length; index++) {
        if (ArrayAcumulado.length === 0) {
          ArrayAcumulado.push(parseInt(ArrayProducaoDiaFiltrado[index].PRODUCAO));
        } else {
          ArrayAcumulado.push(ArrayAcumulado[ArrayAcumulado.length-1] + parseInt(ArrayProducaoDiaFiltrado[index].PRODUCAO));
        }
      }
      return ArrayAcumulado;
    }
    if (exibeProducaoPor === "hl") {
      for (let index = 0; index < ArrayProducaoDiaFiltrado.length; index++) {
        const Produto = this.props.ArrayProdutos.filter((produto) => {
          return produto.COD_PRODUTO === ArrayProducaoDiaFiltrado[index].COD_PRODUTO;
        });
        if (ArrayAcumulado.length === 0) {
          ArrayAcumulado.push(parseFloat(Produto[0].FATOR_CONVERSAO * ArrayProducaoDiaFiltrado[index].PRODUCAO));
        } else {
          ArrayAcumulado.push(ArrayAcumulado[ArrayAcumulado.length-1] + 
                              parseFloat(Produto[0].FATOR_CONVERSAO * ArrayProducaoDiaFiltrado[index].PRODUCAO));
        }
      }
      return ArrayAcumulado;
    }
    if (exibeProducaoPor === "cx/pc") {
      for (let index = 0; index < ArrayProducaoDiaFiltrado.length; index++) {
        const Produto = this.props.ArrayProdutos.filter((produto) => {
          return produto.COD_PRODUTO === ArrayProducaoDiaFiltrado[index].COD_PRODUTO;
        });
        if (ArrayAcumulado.length === 0) {
          if (Produto[0].QUANTIDADE_UNIDADE !== "0") {
            ArrayAcumulado.push(parseFloat(ArrayProducaoDiaFiltrado[index].PRODUCAO / Produto[0].QUANTIDADE_UNIDADE));
          } else {
            ArrayAcumulado.push(parseFloat("0"));
          }
        } else {
          if (Produto[0].QUANTIDADE_UNIDADE !== "0") {
            ArrayAcumulado.push(ArrayAcumulado[ArrayAcumulado.length-1] + 
                              parseFloat(ArrayProducaoDiaFiltrado[index].PRODUCAO / Produto[0].QUANTIDADE_UNIDADE));
          } else {
            ArrayAcumulado.push(ArrayAcumulado[ArrayAcumulado.length-1] + parseFloat("0"));
          }
        }
      }
      return ArrayAcumulado;
    }
  }
  
  defineFormatacao(valorProducao) {
    const formatDecimal = require('format-decimal');
    const opts =  {
      decimal   : ',',
      precision : 0,
      thousands : '.'
    };
    const optsDecimal =  {
      decimal   : ',',
      precision : 2,
      thousands : '.'
    };
    const { exibeProducaoPor } = this.state;
    if (exibeProducaoPor === "cx/pc") {
      return formatDecimal(valorProducao, opts)
    }
    if (exibeProducaoPor === "lt/gfa") {
      return formatDecimal(valorProducao, opts);
    }
    if (exibeProducaoPor === "hl") {
      return formatDecimal(valorProducao, optsDecimal);
    }
    
  }
  
  corEficiencia(Producao){
    const ProdutoLinha = this.props.ArrayProdutosLinha.filter((produtoLinha) => {
      return produtoLinha.COD_LINHA_PRODUCAO === Producao.COD_LINHA_PRODUCAO &&
        produtoLinha.COD_PRODUTO === Producao.COD_PRODUTO;
    });
    if (Producao.PRODUCAO === '0' || Producao.PRODUCAO === null) {
      return "#FF6347";
    } else {
      if (Producao.EFICIENCIA >= ProdutoLinha[0].EFICIENCIA || Producao.EFICIENCIA === '100') {
        return "#32CD32";
      } else {
        return "#FF6347";
      }
    }
  }
  
  exibeModalObservacoes(Producao){
    const ProdutoLinha = this.props.ArrayProdutosLinha.filter((produtoLinha) => {
      return produtoLinha.COD_LINHA_PRODUCAO === Producao.COD_LINHA_PRODUCAO &&
        produtoLinha.COD_PRODUTO === Producao.COD_PRODUTO;
    });
    
    if ((Producao.EFICIENCIA < ProdutoLinha[0].EFICIENCIA && Producao.OBSERVACAO !== null && Producao.OBSERVACAO !== '') || 
        (Producao.EFICIENCIA === ProdutoLinha[0].EFICIENCIA && Producao.OBSERVACAO !== null && Producao.OBSERVACAO !== '')) {
      this.setState({mensagemModalObservacoes:Producao.OBSERVACAO, exibeModalObservacoes: true});
    } else {
      this.setState({mensagemModalObservacoes:Producao.OBSERVACAO, exibeModalObservacoes: false});
    }
    
    
    /*
    if ((Producao.EFICIENCIA < ProdutoLinha[0].EFICIENCIA && Producao.OBSERVACAO !== null) || 
        (Producao.EFICIENCIA < ProdutoLinha[0].EFICIENCIA || (Producao.EFICIENCIA <= ProdutoLinha[0].EFICIENCIA && Producao.OBSERVACAO !== null))) {
      this.setState({mensagemModalObservacoes:Producao.OBSERVACAO, exibeModalObservacoes: true});
    } else {
      this.setState({mensagemModalObservacoes:Producao.OBSERVACAO, exibeModalObservacoes: false});
    }*/
  }
  
  fecharModalObservacoes = () => this.setState({ exibeModalObservacoes: false });
  
  calculaAcumulado(opcaoAcumulado, ArrayProducaoDiaFiltrado){
    let acumulado = 0, producaoAcumulado = 0;
    if (opcaoAcumulado === 'hl') {
      for (let index = 0; index < ArrayProducaoDiaFiltrado.length; index++) {
        const Produto = this.props.ArrayProdutos.filter((produto) => {
          return produto.COD_PRODUTO === ArrayProducaoDiaFiltrado[index].COD_PRODUTO;
        });
        acumulado += parseFloat(Produto[0].FATOR_CONVERSAO * ArrayProducaoDiaFiltrado[index].PRODUCAO);
      }
      return acumulado;
    }
    if (opcaoAcumulado === 'eficiencia') {
      let registro;
      //Soma do total da capacidade.
      for (let index = 0; index < ArrayProducaoDiaFiltrado.length; index++) {
        if (ArrayProducaoDiaFiltrado[index].COD_PRODUTO === '1004' || ArrayProducaoDiaFiltrado[index].COD_PRODUTO === '1005' ||
              ArrayProducaoDiaFiltrado[index].COD_PRODUTO === '1006') {
          let codProdutoValido = 0, codLinhaProducaoValido = 0;
          for (let index = 0; index < ArrayProducaoDiaFiltrado.length; index++) {
            if (ArrayProducaoDiaFiltrado[index].PRODUCAO > '0') {
              codProdutoValido = ArrayProducaoDiaFiltrado[index].COD_PRODUTO;
              codLinhaProducaoValido = ArrayProducaoDiaFiltrado[index].COD_LINHA_PRODUCAO;
            }
          }
          if (codProdutoValido !== 0 && codLinhaProducaoValido !== 0) {
            registro = this.props.ArrayProdutosLinha.filter((produloLinha) => {
              return produloLinha.COD_PRODUTO === codProdutoValido &&
                produloLinha.COD_LINHA_PRODUCAO === codLinhaProducaoValido
            });
            acumulado += parseInt(registro[0].CAPACIDADE);
          } else {
            acumulado += parseInt(0);
          }
          
        } else {
          registro = this.props.ArrayProdutosLinha.filter((produloLinha) => {
            return produloLinha.COD_PRODUTO === ArrayProducaoDiaFiltrado[index].COD_PRODUTO &&
              produloLinha.COD_LINHA_PRODUCAO === ArrayProducaoDiaFiltrado[index].COD_LINHA_PRODUCAO
          });
          acumulado += parseInt(registro[0].CAPACIDADE);
        }
      }
      for (var index = 0; index < ArrayProducaoDiaFiltrado.length; index++) {
        if (ArrayProducaoDiaFiltrado[index].PRODUCAO > '0') {
          producaoAcumulado += parseInt(ArrayProducaoDiaFiltrado[index].PRODUCAO);
        }
      }
      if (isNaN((producaoAcumulado/acumulado)*100)) {
        return parseInt(0)
      } else {
        return parseInt((producaoAcumulado/acumulado)*100);
      }
    }
  }

calculaAcumuladoCaixaPacote(producao){
  if (producao.PRODUCAO !== '0') {
    const Produto = this.props.ArrayProdutos.filter(produto => {
      return produto.COD_PRODUTO === producao.COD_PRODUTO
    });
    return producao.PRODUCAO / Produto[0].QUANTIDADE_UNIDADE;
  } else {
    return 0;                       
  }
}

  
  render() {
    const { ArrayProducaoDia } = this.props;
    let conteudoTabela = [], valorProducao = 0, ArrayAcumulado = [], eficienciaAcumulada = 0, hlAcumulado = 0,
        lataGarrafaAcumulado = 0, caixaPacoteAcumulado = 0;
    const formatDecimal = require('format-decimal');

    const opts =  {
      decimal   : ',',
      precision : 0,
      thousands : '.'
    };
    const optsDecimal =  {
      decimal   : ',',
      precision : 2,
      thousands : '.'
    };
    //Verifica se foi passado nas propriedades o nome da fabrica e da linha, para separar os dados.
    if (this.props.nomeFabrica.length > 0 && this.props.nomeLinha.length > 0 && ArrayProducaoDia.length > 0) {
      const { nomeFabrica, nomeLinha } = this.props;
      const Fabrica = this.props.ArrayFiliais.filter((filial) => {
        return filial.DESC_FILIAL === nomeFabrica 
      });
      const LinhaProducao = this.props.ArrayLinhasProducao.filter((linha) => {
        return linha.DESC_LINHA === nomeLinha && linha.COD_FILIAL === Fabrica[0].COD_FILIAL
      });
      const ArrayProducaoDiaFiltrado = ArrayProducaoDia.filter((producao) => {
        return producao.COD_FILIAL === Fabrica[0].COD_FILIAL && producao.COD_LINHA_PRODUCAO === LinhaProducao[0].COD_LINHA_PRODUCAO
      });
      ArrayAcumulado = this.exibeProducaoAcumulada(ArrayProducaoDiaFiltrado).reverse();
      eficienciaAcumulada = this.calculaAcumulado("eficiencia", ArrayProducaoDiaFiltrado);
      hlAcumulado = formatDecimal(parseFloat(this.calculaAcumulado("hl", ArrayProducaoDiaFiltrado)), optsDecimal);
      let index = -1;
      conteudoTabela =  ArrayProducaoDiaFiltrado.reverse().map(producao => {
        index++;
        valorProducao = this.exibeProducaoFormatada(producao.PRODUCAO, producao.COD_PRODUTO);
        caixaPacoteAcumulado += this.calculaAcumuladoCaixaPacote(producao);
        lataGarrafaAcumulado += parseInt(producao.PRODUCAO);
        
        const ProdutoLinha = this.props.ArrayProdutosLinha.filter((produtoLinha) => {
          return produtoLinha.COD_LINHA_PRODUCAO === producao.COD_LINHA_PRODUCAO &&
            produtoLinha.COD_PRODUTO === producao.COD_PRODUTO;
        });
        
        return (
          <Table.Row key = { producao.id }>
            <Table.Cell style = {{ width:'45px', padding:'0px', textAlign: 'center' }}>{producao.HORA}</Table.Cell>
            <Table.Cell style = {{ width:'120px', padding:'0px', textAlign: 'center' }}>
              {this.buscaNomeProduto(producao.COD_PRODUTO)}</Table.Cell>
            <Table.Cell style = {{ width:'70px', padding:'0px', textAlign: 'center'}}>
              {valorProducao}</Table.Cell>
            <Table.Cell style = {{ width:'50px', padding:'0px', textAlign: 'center', 
                                 color: this.corEficiencia(producao)}}>{producao.EFICIENCIA}</Table.Cell>
            <Table.Cell style = {{ width:'50px', padding:'0px', textAlign: 'center' }}>
              {this.defineFormatacao(ArrayAcumulado[index])}</Table.Cell>
            <Table.Cell style = {{ width:'15px', padding:'0px', textAlign: 'center'}} 
                                  onClick = { this.exibeModalObservacoes.bind(this, producao) }>
              { producao.OBSERVACAO !== '' && producao.OBSERVACAO !== null && 
                (producao.EFICIENCIA < ProdutoLinha[0].EFICIENCIA || (producao.EFICIENCIA <= ProdutoLinha[0].EFICIENCIA && producao.OBSERVACAO !== null)) && <Icon color = 'yellow' name = 'info circle' 
                                                    size = 'large'/> }</Table.Cell>
          </Table.Row>
        );
      }) 
    }
    
    let opcoesResultados = [],
        OpcoesCard = {
          HeaderEsquerda: '',
          HeaderDireira: '',
          DescriptionEsquerda: '',
          DescriptionDireita: ''
        };
    let nomeLinha = this.props.ArrayLinhasProducao.filter((info) => {
      return info.DESC_LINHA === this.props.nomeLinha
    })
    if(nomeLinha.length > 0) {
      let cxpc = ''
      if (nomeLinha[0].DESC_LINHA.indexOf("LATA") !== -1) {
        cxpc = 'PACOTES'
      } else if (nomeLinha[0].DESC_LINHA.indexOf("GARRAFA") !== -1){
        cxpc = 'CAIXAS'
      }
      let texto = '';
      if (nomeLinha[0].DESC_LINHA.indexOf("LATA") !== -1) {
        texto = 'LATAS';
      } else if(nomeLinha[0].DESC_LINHA.indexOf("GARRAFA") !== -1){
        texto = 'GARRAFAS';
      }
      opcoesResultados = [
        {
          text: cxpc,
          value: 'cx/pc'
        },
        {
          text: texto,
          value: 'lt/gfa'
        },
        {
          text: 'HECTOLITROS',
          value: 'hl'
        }
      ]
      switch(this.state.exibeProducaoPor) {
        case 'cx/pc':
          OpcoesCard.HeaderEsquerda = 'HECTOLITROS'
          OpcoesCard.HeaderDireira = texto
          OpcoesCard.DescriptionEsquerda = hlAcumulado
          OpcoesCard.DescriptionDireita = formatDecimal(lataGarrafaAcumulado, opts)
          break;
        case 'lt/gfa':
          OpcoesCard.HeaderEsquerda = cxpc
          OpcoesCard.HeaderDireira = 'HECTOLITROS'
          OpcoesCard.DescriptionEsquerda = formatDecimal(caixaPacoteAcumulado, opts)
          OpcoesCard.DescriptionDireita = hlAcumulado
          break;
        case 'hl':
          OpcoesCard.HeaderEsquerda = cxpc
          OpcoesCard.HeaderDireira = texto
          OpcoesCard.DescriptionEsquerda = formatDecimal(caixaPacoteAcumulado, opts)
          OpcoesCard.DescriptionDireita = formatDecimal(lataGarrafaAcumulado, opts)
          break;
        default:
          // code block
      }
    }


    return(
      <div>
        <Grid.Row style = {{ textAlign: 'center', padding:'0px', fontSize:'12px', width:'350px' }}>
          <Grid columns ='equal'>
            <Grid.Column style={{paddingLeft:'15px',paddingRight:'5px', width:'170px'}}>
              <Card style={{height: '75px'}}>
                <Card.Content>
                  <Card.Header style={{marginTop: '0px'}}>{OpcoesCard.HeaderEsquerda}</Card.Header>
                  <Card.Meta style={{marginTop: '0px'}}>Acumulado</Card.Meta>
                  <Card.Description style={{fontSize:'15px', fontWeight: 'bold', marginTop: '0px'}}>{OpcoesCard.DescriptionEsquerda}</Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column style={{paddingLeft:'5px',paddingRight:'15px', width:'170px'}}>
              <Card style={{height: '75px'}}>
                <Card.Content>
                  <Card.Header style={{marginTop: '0px'}}>{OpcoesCard.HeaderDireira}</Card.Header>
                  <Card.Meta style={{marginTop: '0px'}}>Acumulado</Card.Meta>
                  <Card.Description style={{fontSize:'15px', fontWeight: 'bold', marginTop: '0px'}}>{OpcoesCard.DescriptionDireita}</Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
        </Grid.Row>
        {conteudoTabela.length > 0 &&
          <Grid.Row style = {{ padding:'0px', width:'350px' }}>
            <Grid.Column style = {{ textAlign: 'left', paddingLeft:'0px', paddingRight:'0px', paddingTop:'10px', 
                                    paddingBottom: '0px', width:'350px' }}>
              <span style = {{ fontSize: '14px' }}>Mostrar resultados por{' '}
                <Dropdown inline options = { opcoesResultados } name = "opcTabela" value = { this.state.exibeProducaoPor } 
                                          onChange = { this.alteraExibicaoTabela }/>
              </span>
            </Grid.Column>
          </Grid.Row>
        }
          
          { conteudoTabela.length === 0  &&
              <Message style = {{ textAlign: 'center', width:'350px', fontSize: '14px' }}>
                Sem dados lançados para este dia
              </Message>
          }
          {conteudoTabela.length > 0 &&
            <Table className="ui striped table" unstackable  compact style = {{ padding:'0px', width:'350px'}} >
              <Table.Header style = {{display: 'block', fontSize:'12px',  width:'350px' }}>
                <Table.Row style = {{ textAlign: 'center',  width:'350px' }}>
                  <Table.HeaderCell style = {{ height: '45px', width:'45px', padding:'0px', textAlign: 'center' }}> 
                    Hora 
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'120px', padding:'0px', textAlign: 'center'}}>
                    Produto
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'70px', padding:'0px', textAlign: 'center' }}>
                    Produção
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'50px', padding:'0px', textAlign: 'center'}} >
                    Efi.
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'50px', padding:'0px', textAlign: 'center' }}>
                    Acum.
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'15px', padding:'0px', textAlign: 'center' }}>
                    Info.
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'10px', padding:'0px', textAlign: 'center' }}>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style = {{ fontSize:'10px', display: 'block', height: '360px', overflow: 'auto'}}>
                {conteudoTabela}
              </Table.Body>
            </Table>
          }
        <Modal size = 'mini' open = { this.state.exibeModalObservacoes } onClose = { this.fecharModalObservacoes }>
          <Modal.Header>Detalhes da Produção</Modal.Header>
            <Modal.Content>
              <Container>
                <strong>Observação/Justificativa </strong>
                <p>{this.state.mensagemModalObservacoes}</p>
              </Container>
            </Modal.Content>
          <Modal.Actions>
            <Button color = 'yellow'  content = 'Ok' onClick = { this.fecharModalObservacoes }/>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default ConsultaProducao