import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import PubSub from 'pubsub-js';
class Botao extends Component {
  constructor(props){
    super(props);
    this.state = {
      OpcaoTela: ''
    };
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(nomeBotao) {
    if (this.props.opcaoTela === 'Fabricas') {
      PubSub.publish('opcComponente', {opcComponente: 'LinhasProducao' });
      PubSub.publish('NomeFabrica', {NomeFabrica: nomeBotao });
    }
    if (this.props.opcaoTela === 'Linhas') {
      PubSub.publish('opcComponente', {opcComponente: 'ConsultaProducao' });
      PubSub.publish('NomeLinha', {NomeLinha: nomeBotao });
    }
  }
  
  render() {
    return(
      <div>
        <Button style = {{ width: '160px', height: "80px", marginTop: '0px', marginBottom: '5px', marginLeft: '0px',
                           marginRight: '5px', padding: '0px'}} 
                onClick = { this.handleClick.bind(this, this.props.nomeBotao) }>{ this.props.nomeBotao }</Button>
      </div>
    );
  }
}

export default Botao