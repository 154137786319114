import React, { Component } from 'react';
import {Form, Grid, Header, Image, Segment, Transition, Loader, Message} from 'semantic-ui-react';
import logoProdBeer from '../../img/prodbeer.png';
import logoCidadeImperial from '../../img/logo-cidade-imperial.png';
import { salvaToken, salvaCodUsuario, versao } from '../../servicos/autenticacao';
import PubSub from 'pubsub-js';

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      transicao: false,
      codUsuario: '',
      senha: '',
      erro: '',
      mensagemSuporte: '',
      loader: false
    };
    this.handleClickMensagemSuporte = this.handleClickMensagemSuporte.bind(this);
  }
  
  componentDidMount(){
    this.setState({transicao: true});
  }
  
  componentWillMount(){
    PubSub.subscribe('tokenInvalido', function(topic,data){
      this.setState({erro: data.tokenInvalido});
    }.bind(this));
  }
  
  handleClickMensagemSuporte() {
    const { mensagemSuporte } = this.state;
    this.setState({
      mensagemSuporte: mensagemSuporte === true ? false : true
    });
  }
  
  handleLogin = evento => {
    evento.preventDefault();
    this.setState({loader: true});
    const { usuario, senha } = this.state;
    if (!usuario || !senha){
      this.setState({error:"Preencha os campos Usuário e Senha!", loader: false});
    } else {
      const request = require('request'),
          opcoes = {
            method: 'POST',
            url:'https://inteligenciaindustrial.com/prodbeer/autenticacao/login',
            headers: {
              'content-type': 'application/json'
            },
            body: {"usuario": usuario, "senha": senha, plataforma:"prodbeer" + versao},
            json: true
          };
      request(opcoes, function (error, response, body) {
        if (body !== undefined) {
          if (body.mensagem === 'autenticacao valida') {
            salvaToken(body.token);
            salvaCodUsuario(body.cod_usuario);
            PubSub.publish('logado', { logado:true });
            this.setState({ loader:false });
          } else {
            this.setState({ error:"Usuário ou senha inválidos! Tente novamente", loader: false });
          }
        } else {
          this.setState({ error:"Não foi possivel conectar-se a internet tente novamente", loader: false });
        }
      }.bind(this));
    }
  }
  
  render() {
    return(
      <Transition animation = 'fade down' visible = {this.state.transicao} duration = {3000}>
        <div className = 'login-form'>
          <style>{ `body > div, body > div > div, body > div > div > div.login-form { height: 100%; }` } </style>
          <Grid textAlign = 'center' style = {{ height: '100%' }} verticalAlign = 'middle'>
            <Grid.Column style = {{ maxWidth: '350px' }}>
              <Header as = 'h2' textAlign = 'center'>
                <Image src = {logoProdBeer} style = {{ width: '400px'}} />
              </Header>
              <Form size = 'large' onSubmit = {this.handleLogin}>
                <Segment stacked>
                  <Form.Input fluid icon = 'user' iconPosition = 'left' placeholder = 'Usuário' 
                              type = "text" name = "usuario" 
                              onChange = { e => this.setState({ usuario: e.target.value })} />
                  <Form.Input fluid icon = 'lock' iconPosition = 'left' placeholder = 'Senha' 
                              type = "password" name = "senha"
                              onChange = { e => this.setState({ senha: e.target.value })} />
                  { this.state.loader && <Loader active /> }
                  <button class = "ui black large fluid icon right labeled button" > ENTRAR
                    <i aria-hidden = "true" class = "sign in alternate icon"></i>
                  </button>
                </Segment>
              </Form>
              <Message>
                Esqueceu usuário ou senha? 
                <a onClick = {this.handleClickMensagemSuporte} style = {{ cursor: "pointer" }}> Clique aqui! </a>
              </Message>
              {this.state.mensagemSuporte && <Message>
                <i onClick = {this.handleClickMensagemSuporte} aria-hidden = 'true' class = 'close icon' />
                <p>Caso tenha esquecido ou não possua usuário ou senha, mande um e-mail para: bruiz@itucentral.com.br</p>
              </Message>
              }
              { 
                this.state.error && <Message color = 'red'> {this.state.error} </Message> 
              }
              <p class = "ui black center aligned header" style = {{ fontSize: '10px'}}>
                Desenvolvido por
                <Image src = {logoCidadeImperial} style = {{ width: '75px'}} />
              </p>
              <p class = "ui black center aligned header" style = {{ fontSize: '10px'}}>
                {versao}
              </p>
            </Grid.Column>
          </Grid>
        </div>
      </Transition>
    );
  }
}
export default Login;