import React, { Component } from 'react';
import { Header, Grid, Icon, Image, Sidebar, Menu, Segment, Divider } from 'semantic-ui-react';
import { getToken, removeToken, getCodUsuario } from '../../servicos/autenticacao';
import logoProdBeer from '../../img/prodbeer.png';
import PubSub from 'pubsub-js';
import SubCabecalho from '../cabecalho/sub_cabecalho.js';
import AlterarSenha from '../troca_senha/troca_senha.js';
import ConsultaProducao from '../consulta_producao/consulta_producao.js';
import Consolidado from '../consolidado/consolidado.js';
import ConsolidadoFaturamento from '../faturamento/faturamentoconsolidado.js'
import Relatorio from '../relatorio/relatorio.js';

class Cabecalho extends Component {
  constructor(props){
    super(props);
    this.state = {
      exibeMenu: false,
      opcComponente: '',
      nomeFabrica: '',
      nomeLinha: '',
      ArrayFiliais: [],
      ArrayLinhasProducao: [],
      ArrayProdutos: [],
      ArrayProdutosLinha: [],
      permissaoFaturamento: false
    };
    this.mostrarMenu = this.mostrarMenu.bind(this);
    this.alterarSenha = this.alterarSenha.bind(this);
    this.exibeRelatorio = this.exibeRelatorio.bind(this);
    this.menuFabricas = this.menuFabricas.bind(this);
    this.desconectar = this.desconectar.bind(this);
  }
  
  componentWillMount(){
    PubSub.subscribe('opcComponente', function(topic,data){
      this.setState({opcComponente: data.opcComponente});
    }.bind(this));
    PubSub.subscribe('NomeFabrica', function(topic,data){
      this.setState({nomeFabrica: data.NomeFabrica});
    }.bind(this));
    PubSub.subscribe('NomeLinha', function(topic,data){
      this.setState({nomeLinha: data.NomeLinha});
    }.bind(this));
  }
  
  componentDidMount(){
    //Realizar requests na API.
    const request = require('request'),
          opcoesFiliais = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/prodbeer/filial/filiais',
            headers : {
              'Authorization': getToken()
            },
            json: true
          },
          opcoesLinhasProducao = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/prodbeer/linhaproducao/linhasProducao',
            headers : {
              'Authorization': getToken()
            },
            json: true
          },
          opcoesProdutos = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/prodbeer/produto/produtos',
            headers : {
              'Authorization': getToken()
            },
            json: true
          },
          opcoesProdutosLinha = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/prodbeer/produto/produtosLinha',
            headers : {
              'Authorization': getToken()
            },
            json: true
          },
          opcoesPermissaoFaturamento = {
              method: 'POST',
              url:'https://inteligenciaindustrial.com/prodbeer/faturamento/permissaofaturamento',
              headers: {
                'content-type': 'application/json',
                'Authorization': getToken()
              },
              body: { "codUsuario": getCodUsuario() },
              json: true
            };
    request(opcoesFiliais, function (error, response, body) {
      if (body.Filiais) {
        this.setState({ ArrayFiliais:body.Filiais });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        removeToken();
      }
    }.bind(this));
    request(opcoesLinhasProducao, function (error, response, body) {
      if (body.LinhasProducao) {
        this.setState({
          ArrayLinhasProducao:body.LinhasProducao
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        removeToken();
      }
    }.bind(this));
    request(opcoesProdutos, function (error, response, body) {
      if (body.Produtos) {
        this.setState({
          ArrayProdutos:body.Produtos
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        removeToken();
      }
    }.bind(this));
    request(opcoesProdutosLinha, function (error, response, body) {
      if (body.ProdutosLinha) {
        this.setState({
          ArrayProdutosLinha:body.ProdutosLinha
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        removeToken();
      }
    }.bind(this));
    request(opcoesPermissaoFaturamento, function (error, response, body) {
              let Permissoes = body.permissao;
              
              if (typeof(Permissoes) === "object") {
                Permissoes.forEach(function(value){
                  if (value.FUNCIONALIDADE === "faturamento") {
                    this.setState({
                      permissaoFaturamento: true
                    });
                  }
                }.bind(this))
              }
            }.bind(this));


  }
  
  mostrarMenu() {
    this.setState({
      exibeMenu: this.state.exibeMenu === true ? false : true
    });
  }
  
  menuFabricas() {
    this.setState({ opcComponente: "" });
  }
  
  alterarSenha() {
    this.setState({ opcComponente: "AlterarSenha" });
  }
  
  exibeRelatorio() {
    this.setState({ opcComponente: "ExibeRelatorio" });
  }
  
  desconectar() {
    PubSub.publish('logado', { logado:false });
    removeToken();
  }
  
  render() {
    const { exibeMenu, ArrayFiliais, ArrayLinhasProducao, opcComponente, nomeFabrica, nomeLinha, permissaoFaturamento } = this.state;
    
    let objComponente;
    switch(opcComponente) {
      case "ConsultaProducao":
        objComponente = <ConsultaProducao tituloTela = {nomeFabrica + " " + nomeLinha} 
                                nomeFabrica = {nomeFabrica}
                                nomeLinha = {nomeLinha}
                                ArrayFiliais = { this.state.ArrayFiliais } 
                                ArrayLinhasProducao = { this.state.ArrayLinhasProducao } 
                                ArrayProdutos = {this.state.ArrayProdutos} 
                                ArrayProdutosLinha = {this.state.ArrayProdutosLinha} />;
        break;
      case "LinhasProducao":
        objComponente = <SubCabecalho tituloTela = {"SELECIONE UMA LINHA DE " + nomeFabrica }
                                      nomeFilial = { nomeFabrica }
                                      ArrayFiliais = { ArrayFiliais }
                                      ArrayLinhasProducao = { ArrayLinhasProducao } 
                                      opcaoTela = {"Linhas"}  permissaoFaturamento = { permissaoFaturamento }/>;
        break;
      case "ConsolidadoLinhas":
        objComponente = <Consolidado  tituloTela = { "CONSOLIDADO" }
                                      nomeFabrica = { nomeFabrica }
                                      ArrayProdutos = {this.state.ArrayProdutos}
                                      ArrayFiliais = { this.state.ArrayFiliais }
                                      opcaoTela = {"Linhas"} />;
        break;
      case "ConsolidadoFabricas":
        objComponente = <Consolidado  tituloTela = { "CONSOLIDADO" }
                                      nomeFabrica = { "UNIDADES FABRIS" }
                                      ArrayProdutos = {this.state.ArrayProdutos}
                                      opcaoTela = {"Fabricas"} />;
        break;
      case "ConsolidadoFaturamento":
        objComponente = <ConsolidadoFaturamento  tituloTela = { "CONSOLIDADO" }
                                      nomeFabrica = { "UNIDADES FABRIS" }
                                      ArrayProdutos = {this.state.ArrayProdutos}
                                      opcaoTela = {"Fabricas"} />;
        break;
      case "ExibeRelatorio":
        objComponente = <Relatorio    tituloTela = { "PROGRAMADO VS REALIZADO" }
                                      opcaoTela = {"Relatorio"} />;
        break;
      case "AlterarSenha":
        objComponente = <AlterarSenha />
        break;
      default:
        objComponente = <SubCabecalho tituloTela = {"SELECIONE UMA FÁBRICA"} ArrayFiliais = { ArrayFiliais } 
                                      opcaoTela = {"Fabricas"} permissaoFaturamento = { permissaoFaturamento } />;
    }
    return (
      <div>        
        <Header style = {{ height: '85px' }}>
          <Grid>
            <Grid.Column width = { 2 }>
              <Icon name = 'sidebar' style = {{ margin: '5px', marginTop:'20px' }} disabled = { exibeMenu } 
                    onClick = { this.mostrarMenu }/>
            </Grid.Column >
            <Grid.Column width = {12} style = {{ textAlign: 'center' }}>
              <Header.Content>
                <Image src = {logoProdBeer} style = {{ width: '150px', marginTop:'20px' }} 
                       onClick = { this.menuFabricas }/>
              </Header.Content>
            </Grid.Column>
            <Grid.Column width = { 2 }></Grid.Column>
          </Grid>
        </Header>
        <Divider style = {{ margin:'0px' }} />
        <Sidebar.Pushable>
          <Sidebar as = { Menu } animation = 'overlay' inverted onHide = { this.mostrarMenu } 
                   direction = 'top' visible = { exibeMenu } width = 'thin'>
            <Menu.Item as = 'a' onClick = { this.alterarSenha }>
              <Icon name = 'edit outline' />
              Alterar Senha
            </Menu.Item>
            <Menu.Item as = 'a' onClick = { this.exibeRelatorio }>
              <Icon name = 'chart bar' />
              Relatório
            </Menu.Item>
            <Menu.Item as = 'a' onClick = { this.desconectar }>
              <Icon name = 'log out' />
                Sair
            </Menu.Item>
          </Sidebar>
          <Sidebar.Pusher>
            <Segment basic>
              { objComponente }
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

export default Cabecalho;