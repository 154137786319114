import React, { Component } from 'react';
import { Grid, Header, Icon, Button } from 'semantic-ui-react';
import PubSub from 'pubsub-js';
import Botao from '../geral/botao.js'

class SubCabecalho extends Component {
  constructor(props){
    super(props);
    this.state = {
      OpcaoTela: '',
      Botoes: []
    };
    this.voltarTelaAnterior = this.voltarTelaAnterior.bind(this);
    this.exibeConsolidado = this.exibeConsolidado.bind(this);
    this.exibeFaturamentoConsolidado = this.exibeFaturamentoConsolidado.bind(this);
  }
  
  voltarTelaAnterior(){
    PubSub.publish('opcComponente', {opcComponente:''});
  }
  
  exibeConsolidado(){
    if (this.props.opcaoTela === "Linhas") {
      PubSub.publish('opcComponente', {opcComponente: 'ConsolidadoLinhas'});
    } else {
      PubSub.publish('opcComponente', {opcComponente: 'ConsolidadoFabricas'});
    }
    //console.log("Chamar o componente concolidado..." + this.props.opcaoTela);
  }
  
  exibeFaturamentoConsolidado() {
    PubSub.publish('opcComponente', {opcComponente: 'ConsolidadoFaturamento'});
  }
  
  render() {
    let Botoes = [];
    if (this.props.opcaoTela === 'Fabricas') {
      this.props.ArrayFiliais.map(Filial => {
        if (Filial.COD_FILIAL !== '101010') {
          Botoes.push(
            <Botao nomeBotao = { Filial.DESC_FILIAL } key = { Filial.id } opcaoTela = {"Fabricas"} />
          );
        }
        return Filial;
      });
    }
    if (this.props.opcaoTela === 'Linhas') {
      const Filial = this.props.ArrayFiliais.filter((filial => {
        return filial.DESC_FILIAL === this.props.nomeFilial;
      }));
      let Linhas;
      if (Filial.length > 0) {
        Linhas = this.props.ArrayLinhasProducao.filter((linha => {
          return linha.COD_FILIAL === Filial[0].COD_FILIAL;
        }));
        Linhas.map(Linha => {
          Botoes.push(
            <Botao nomeBotao = {Linha.DESC_LINHA} key = {Linha.id} opcaoTela = {'Linhas'} />
          );
          return Linha;
        });
      }
    }
    
    return (
      <div>
        <Grid style={{ height: '100%', width: '350px', margin: 'auto' }}>
          <Grid.Row textAlign='center'  style = {{padding: '0px'}}>
            <Grid.Column width = {2} ></Grid.Column>
            <Grid.Column width = {12} style = {{paddingTop: '0px'}}>
              <Header as = 'h5'>{this.props.tituloTela}</Header>
            </Grid.Column>
            <Grid.Column width = {2}>
              {
                this.props.opcaoTela !== 'Fabricas' && 
                <Icon floated='right' name='reply'  style = {{paddingTop: '0px'}} onClick={this.voltarTelaAnterior}/>
              }<br/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{paddingTop: '25px'}}>
            {Botoes}
            <Button style = {{ width: '325px', height: "80px", marginTop: '0px', marginBottom: '5px', marginLeft: '0px',
                               marginRight: '5px', padding: '0px'}} onClick = {this.exibeConsolidado} >CONSOLIDADO</Button>
            {
              this.props.opcaoTela === 'Fabricas' && this.props.permissaoFaturamento === true &&
              <Button style = {{ width: '325px', height: "80px", marginTop: '0px', marginBottom: '5px', marginLeft: '0px',
                               marginRight: '5px', padding: '0px'}} onClick = {this.exibeFaturamentoConsolidado} >FATURAMENTO</Button>
            }
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default SubCabecalho;