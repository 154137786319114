import React, { Component } from 'react';
import {Form, Grid, Header, Segment, Loader, Icon} from 'semantic-ui-react'
import PubSub from 'pubsub-js';
import { getCodUsuario, getToken, removeToken } from '../../servicos/autenticacao';

class TrocaSenha extends Component {
  constructor(props){
    super(props);
    this.state = {
      nome: '',
      senha: '',
      erro: '',
      sucesso: '',
      loader: false,
      isToggleOn: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleChange(evento) {
    this.setState({ senha: evento.target.value });
  }
  
  handleSubmit(evento) {
    evento.preventDefault();
    this.setState({loader: true, erro: '', sucesso: ''})
    if (this.state.senha === '') {
      this.setState({ erro: "Senha não pode ser vazia! Tente novamente.", succes: '',
                      loader: false });
    } else {
      const request = require('request'),
            opcoes = {
              method: 'PUT',
              url:'https://inteligenciaindustrial.com/prodbeer/usuario/alteraSenha',
              headers: {
                'Authorization': getToken()
              },
              body: {"usuario": getCodUsuario(), "senha": this.state.senha},
              json: true
            };
      request(opcoes, function (error, response, body) {
        if (body !== undefined) {
          if (body.Usuario){
            this.setState({ loader: false, senha: "", erro: '', sucesso: "Senha alterada com sucesso!"});
          } else {
            PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
            PubSub.publish('logado', { logado:false });
            removeToken()
          }
        } else {
          this.setState({ error: "Não foi possivel conectar-se a internet tente novamente.", loader: false })
        }
      }.bind(this));
    }
  }
  
  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }
  
  handleClickVoltar(){
    PubSub.publish('opcComponente', {opcComponente:''});
  }
  
  render() {
    return (
      <div>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: '350px' }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={12}>
                  <Header as='h5'>ALTERAR SENHA</Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Icon floated='right' name='reply' onClick={this.handleClickVoltar}/><br/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br/><br/>
              <Form size='large' onSubmit={this.handleSubmit}>
                <Segment stacked>
                  <Form.Input fluid icon='lock' iconPosition='left' placeholder='Nova Senha' type="password" 
                              name = "senha" onChange={this.handleChange} />
                  { this.state.loader && <Loader active /> }
                  <button class="ui black large fluid icon right labeled button" > SALVAR
                    <i aria-hidden="true" class="save outline"></i>
                  </button>
                </Segment>
              </Form>
              { this.state.erro && 
                  <div class = "ui red message" style = {{ backgroundColor: '#ffe8e6' }}>
                    { this.state.erro }
                  </div>
              }
              { this.state.sucesso && 
                  <div class="ui green message" style={{backgroundColor: '#fbfbfb' }}>
                    { this.state.sucesso }
                  </div>
              }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default TrocaSenha;