import React, { Component } from 'react';
import { Grid, Header, Icon, Segment, Button, Label, Card, Table, Loader, Message, Dropdown } from 'semantic-ui-react'
import PubSub from 'pubsub-js';
import { getToken, removeToken } from '../../servicos/autenticacao';

class ConsolidadoFaturamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataExibicao: '',
      ArrayProducaoDia: [],
      DadosFaturamentoOriginal: [],
      ConteudoTabela: [],
      AcumuladoHl: 0,
      AcumuladoPcCx: 0,
      loader: false,
      mensagem: '',
      exibeMensagem: false, 
      opcAgrupamento: 'produto',
      DadosFaturamentoOriginal: [],
      DadosFaturamentoFiltrado: [],
      aturamentoAcumulado: 0,
      pacotesCaixasAcumulado: 0
    }
    this.voltarTelaAnterior = this.voltarTelaAnterior.bind(this);
    this.atualizaDados = this.atualizaDados.bind(this);
    this.atualizaParaDataAtual = this.atualizaParaDataAtual.bind(this);
    this.alteraAgrupador = this.alteraAgrupador.bind(this);
  }
  
  //
  componentDidMount() {   
    let dataAtual = new Date(),
        descAnoMes = dataAtual.getFullYear() +" - "+ ("0" + parseInt(dataAtual.getMonth() + 1)).slice(-2);
    
    
    this.setState({dataExibicao: descAnoMes, loader: true});
    
    let request = require('request');
    
    const opcoesFaturamento = {
      method: 'GET',
      url:`https://inteligenciaindustrial.com/prodbeer/faturamento/dadosfaturamento/${descAnoMes.split("-")[0].trim()+"-"+descAnoMes.split("-")[1].trim()}`,
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    
     request(opcoesFaturamento, function (error, response, body) {
       if (body !== undefined) {
         if (body.Faturamento) {
           let dadosFaturamentoFiltrado = body.Faturamento;
           
           this.setState({ DadosFaturamentoFiltrado:dadosFaturamentoFiltrado, dataExibicao: descAnoMes, exibeMensagem: false });
           this.montaTabela(dadosFaturamentoFiltrado, this.state.opcAgrupamento);
         } else if (body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
       } else {
         this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", DadosFaturamentoFiltrado:[], 
                        dataExibicao: descAnoMes, exibeMensagem: true, loader: false, });
       }
    }.bind(this));
  }
  
  montaTabela(DadosFaturamentoFiltrado, opcAgrupamento){
    this.setState({loader: true});
    
    let ConteudoTabela = [], campo = opcAgrupamento, DadosAgrupados = [], temValor = [], 
         quantidade = 0, valor = 0, faturamentoAcumulado = 0, pacotesCaixasAcumulado = 0;
    
    if (opcAgrupamento === "dia") {
      for (let index = 0; index < DadosFaturamentoFiltrado.length; index++) {
        DadosFaturamentoFiltrado[index].VALOR = DadosFaturamentoFiltrado[index].VALOR.replace(",",".") * 1;
        DadosFaturamentoFiltrado[index].QUANTIDADE = parseInt(DadosFaturamentoFiltrado[index].QUANTIDADE);
        if (DadosAgrupados.length === 0) {
          DadosAgrupados.push(DadosFaturamentoFiltrado[index]);
        } else {
          //Verificar se existe o valor no array
          temValor = DadosAgrupados.filter(function(value){
            return value.DIA === DadosFaturamentoFiltrado[index].DIA
          });
          
          if (temValor.length > 0) {
            DadosAgrupados.forEach(function(value, pos) {
              if (DadosFaturamentoFiltrado[index].DIA === value.DIA) {
                quantidade = DadosFaturamentoFiltrado[index].QUANTIDADE + value.QUANTIDADE;
                valor = DadosFaturamentoFiltrado[index].VALOR + value.VALOR;
                value.QUANTIDADE = quantidade;
                value.VALOR = valor
              }
            });
          } else {
            DadosAgrupados.push(DadosFaturamentoFiltrado[index]);
          }
        }
      }
      DadosAgrupados = DadosAgrupados.sort(function(a, b) {
        return a.DIA > b.DIA ? -1 : (a.DIA < b.DIA) ? 1 : 0;
      });
    } else if (opcAgrupamento === "filial") {
      for (let index = 0; index < DadosFaturamentoFiltrado.length; index++) {
        DadosFaturamentoFiltrado[index].VALOR = DadosFaturamentoFiltrado[index].VALOR.replace(",",".") * 1;
        DadosFaturamentoFiltrado[index].QUANTIDADE = parseInt(DadosFaturamentoFiltrado[index].QUANTIDADE);
       
        if (DadosAgrupados.length === 0) {
          DadosAgrupados.push(DadosFaturamentoFiltrado[index]);
        } else {
          //Verificar se existe o valor no array
          temValor = DadosAgrupados.filter(function(value){
            return value.FILIAL === DadosFaturamentoFiltrado[index].FILIAL
          });
          
          if (temValor.length > 0) {
            DadosAgrupados.forEach(function(value, pos) {
              if (DadosFaturamentoFiltrado[index].FILIAL === value.FILIAL) {
                quantidade = DadosFaturamentoFiltrado[index].QUANTIDADE + value.QUANTIDADE;
                valor = DadosFaturamentoFiltrado[index].VALOR + value.VALOR;
                value.QUANTIDADE = quantidade;
                value.VALOR = valor
              }
            });
          } else {
            DadosAgrupados.push(DadosFaturamentoFiltrado[index]);
          }
        }
      }
      DadosAgrupados = DadosAgrupados.sort(function(a, b) {
        return a.VALOR > b.VALOR ? -1 : (a.VALOR < b.VALOR) ? 1 : 0;
      });
    } else if (opcAgrupamento === "produto") {
      for (let index = 0; index < DadosFaturamentoFiltrado.length; index++) {
        DadosFaturamentoFiltrado[index].VALOR = DadosFaturamentoFiltrado[index].VALOR.replace(",",".") * 1;
        DadosFaturamentoFiltrado[index].QUANTIDADE = parseInt(DadosFaturamentoFiltrado[index].QUANTIDADE);
        if (DadosAgrupados.length === 0) {
          DadosAgrupados.push(DadosFaturamentoFiltrado[index]);
        } else {
          //Verificar se existe o valor no array
          temValor = DadosAgrupados.filter(function(value){
            return value.PRODUTO === DadosFaturamentoFiltrado[index].PRODUTO
          });
          
          if (temValor.length > 0) {
            DadosAgrupados.forEach(function(value, pos) {
              if (DadosFaturamentoFiltrado[index].PRODUTO === value.PRODUTO) {
                quantidade = DadosFaturamentoFiltrado[index].QUANTIDADE + value.QUANTIDADE;
                valor = DadosFaturamentoFiltrado[index].VALOR + value.VALOR;
                value.QUANTIDADE = quantidade;
                value.VALOR = valor
              }
            });
          } else {
            DadosAgrupados.push(DadosFaturamentoFiltrado[index]);
          }
        }
      }
      DadosAgrupados = DadosAgrupados.sort(function(a, b) {
        return parseInt(a.VALOR) > parseInt(b.VALOR) ? -1 : (parseInt(a.VALOR) < parseInt(b.VALOR)) ? 1 : 0;
      });
    } else if (opcAgrupamento === "estado") {
      for (let index = 0; index < DadosFaturamentoFiltrado.length; index++) {
        DadosFaturamentoFiltrado[index].VALOR = DadosFaturamentoFiltrado[index].VALOR.replace(",",".") * 1;
        DadosFaturamentoFiltrado[index].QUANTIDADE = parseInt(DadosFaturamentoFiltrado[index].QUANTIDADE);
        if (DadosAgrupados.length === 0) {
          DadosAgrupados.push(DadosFaturamentoFiltrado[index]);
        } else {
          //Verificar se existe o valor no array
          temValor = DadosAgrupados.filter(function(value){
            return value.ESTADO === DadosFaturamentoFiltrado[index].ESTADO
          });
          
          if (temValor.length > 0) {
            DadosAgrupados.forEach(function(value, pos) {
              if (DadosFaturamentoFiltrado[index].ESTADO === value.ESTADO) {
                quantidade = DadosFaturamentoFiltrado[index].QUANTIDADE + value.QUANTIDADE;
                valor = DadosFaturamentoFiltrado[index].VALOR + value.VALOR;
                value.QUANTIDADE = quantidade;
                value.VALOR = valor
              }
            });
          } else {
            DadosAgrupados.push(DadosFaturamentoFiltrado[index]);
          }
        }
      }
      DadosAgrupados = DadosAgrupados.sort(function(a, b) {
        return a.VALOR > b.VALOR ? -1 : (a.VALOR < b.VALOR) ? 1 : 0;
      });
    }
    
    DadosAgrupados.forEach(function (value, index){
      faturamentoAcumulado += value.VALOR;
      pacotesCaixasAcumulado += value.QUANTIDADE;
    });
    
    DadosAgrupados.forEach(function (value, index) {
      ConteudoTabela.push(
        <Table.Row key = {index}>
          <Table.Cell style = {{ width:'220px', padding:'5px', textAlign: 'center' }}> 
            {
              opcAgrupamento === "dia" ? value.DIA : 
              opcAgrupamento === "filial" ? value.FILIAL : 
              opcAgrupamento === "cliente" ? value.CLIENTE :
              opcAgrupamento === "produto" ? value.PRODUTO :
              opcAgrupamento === "estado" ? value.ESTADO :
              ""
            }
          </Table.Cell>
          <Table.Cell style = {{ width:'60px', padding:'5px', textAlign: 'center' }}>
            {this.formataValores(value.QUANTIDADE)}
          </Table.Cell>
          <Table.Cell style = {{ width:'60px', padding:'5px', textAlign: 'center'}} >
            {this.formataValores(value.VALOR)}
          </Table.Cell>
            <Table.Cell style = {{ width:'10px', padding:'5px', textAlign: 'center' }}>
          </Table.Cell>
        </Table.Row>
      )
      
    }.bind(this));
    
    this.setState({
      ConteudoTabela: ConteudoTabela, loader: false, faturamentoAcumulado: faturamentoAcumulado, pacotesCaixasAcumulado: pacotesCaixasAcumulado
    });
  }
  
   
  formataValores(valor){
    const formatDecimal = require('format-decimal');
    const opts =  {
      decimal   : ',',
      precision : 0,
      thousands : '.'
    };
    
    if (valor) {
      valor = formatDecimal(valor,opts)
    }
    
    return valor;
  }
  
  
  //
  voltarTelaAnterior(){
    if (this.props.opcaoTela === "Linhas") {
      PubSub.publish('opcComponente', {opcComponente:'LinhasProducao'});
    } else {
      PubSub.publish('opcComponente', {opcComponente:''});
    }
  }
  //
  alteraDataExibicao(opcaoClick) {
    let dataAtual = new Date(),
        dataCorrente = this.state.dataExibicao,
        mes = 0;
    
    let dataCorrenteFormatada = new Date(dataCorrente.split("-")[0].trim(), parseInt(dataCorrente.split("-")[1].trim())-1);
    
    this.setState({loader: true});
    
    if (opcaoClick === 'diminuir') {      
      dataCorrenteFormatada.setMonth(dataCorrenteFormatada.getMonth()-1);
      
      dataCorrente = dataCorrenteFormatada.getFullYear() +" - "+ ("0" + parseInt(dataCorrenteFormatada.getMonth()+1)).slice(-2);
      
      this.atualizaDados(dataCorrente, dataCorrente);
    }
    
    if (opcaoClick === 'adicionar') {      
      dataCorrenteFormatada.setMonth(dataCorrenteFormatada.getMonth()+1);
      
      dataCorrente = dataCorrenteFormatada.getFullYear() +" - "+ ("0" + parseInt(dataCorrenteFormatada.getMonth()+1)).slice(-2) ;
      
      this.atualizaDados(dataCorrente, dataCorrente);
    }
    
    this.setState({dataExibicao: dataCorrente});
  }
  //
  atualizaParaDataAtual() {
    this.setState({loader: true});
    let dataAtual = new Date(),
        dataAtualFormatada = dataAtual.getDate() +'/'+ (dataAtual.getMonth()+1)+'/' +dataAtual.getFullYear(),
        dia = dataAtual.getDate(),
        diaF = dia.toString().length > 1 ? dia : "0"+dia,
        mes = dataAtual.getMonth()+1,
        mesF = mes.toString().length > 1 ? mes : "0"+mes;
    let dataConsulta = dataAtual.getFullYear() + '-' + mesF + '-' + diaF;
    let opcAgrupamento = this.state.opcAgrupamento;
    
    if (opcAgrupamento === "fabrica") {
      opcAgrupamento = "filial";
    }
    
    let descAnoMes = dataAtual.getFullYear() +" - "+ ("0" + parseInt(dataAtual.getMonth() + 1)).slice(-2);
   
    
    let request = require('request');
    
    const opcoesFaturamento = {
      method: 'GET',
      url:`https://inteligenciaindustrial.com/prodbeer/faturamento/dadosfaturamento/${descAnoMes.split("-")[0].trim()+"-"+descAnoMes.split("-")[1].trim()}`,
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    
    
    request(opcoesFaturamento, function (error, response, body) {
       if (body !== undefined) {
         if (body.Faturamento) {
           let dadosFaturamentoFiltrado = body.Faturamento;
           
           this.setState({ DadosFaturamentoFiltrado:dadosFaturamentoFiltrado, dataExibicao: descAnoMes, exibeMensagem: false });
           this.montaTabela(dadosFaturamentoFiltrado, this.state.opcAgrupamento);
         } else if (body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
       } else {
         this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", DadosFaturamentoFiltrado:[], 
                        dataExibicao: descAnoMes, exibeMensagem: true, loader: false, });
       }
    }.bind(this));
  }
  //
  atualizaDados(dataConsulta, dataExibicao){
    let dadosFiltrados = [], opcAgrupamento = this.state.opcAgrupamento;
    
    if (opcAgrupamento === "fabrica") {
      opcAgrupamento = "filial";
    }
    
    let request = require('request');
    
    const opcoesFaturamento = {
      method: 'GET',
      url:`https://inteligenciaindustrial.com/prodbeer/faturamento/dadosfaturamento/${dataExibicao.split("-")[0].trim()+"-"+dataExibicao.split("-")[1].trim()}`,
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    
    request(opcoesFaturamento, function (error, response, body) {
       if (body !== undefined) {
         if (body.Faturamento) {
           let dadosFaturamentoFiltrado = body.Faturamento;
           
           this.setState({ DadosFaturamentoFiltrado:dadosFaturamentoFiltrado, dataExibicao: dataExibicao, exibeMensagem: false, opcAgrupamento: opcAgrupamento });
           this.montaTabela(dadosFaturamentoFiltrado, this.state.opcAgrupamento);
         } else if (body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
       } else {
         this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", DadosFaturamentoFiltrado:[], 
                        dataExibicao: dataExibicao, exibeMensagem: true, loader: false, });
       }
    }.bind(this));
  }
  
  
  alteraAgrupador(event, data){
    let { DadosFaturamentoOriginal, dataExibicao } = this.state, opcAgrupamento = "";
    
    if (data.value === "fabrica") {
      opcAgrupamento = "filial";
    } else {
      opcAgrupamento = data.value
    }
    
    let request = require('request');
    
    const opcoesFaturamento = {
      method: 'GET',
      url:`https://inteligenciaindustrial.com/prodbeer/faturamento/dadosfaturamento/${dataExibicao.split("-")[0].trim()+"-"+dataExibicao.split("-")[1].trim()}`,
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    
    
    request(opcoesFaturamento, function (error, response, body) {
       if (body !== undefined) {
         if (body.Faturamento) {
           let dadosFaturamentoFiltrado = body.Faturamento;
           
           this.setState({ DadosFaturamentoFiltrado:dadosFaturamentoFiltrado, dataExibicao: dataExibicao, exibeMensagem: false, opcAgrupamento: opcAgrupamento });
           this.montaTabela(dadosFaturamentoFiltrado, this.state.opcAgrupamento);
         } else if (body.error === 'erro no token'){
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          removeToken();
        }
       } else {
         this.setState({ mensagem:"Não foi possivel conectar-se a internet tente novamente", DadosFaturamentoFiltrado:[], 
                        dataExibicao: dataExibicao, exibeMensagem: true, loader: false, });
       }
    }.bind(this));
    
    /*
     request(opcoesFaturamento, function (error, response, body) {
       let dadosFaturamentoFiltrado = body.Faturamento
       
       this.setState({ DadosFaturamentoFiltrado:dadosFaturamentoFiltrado, dataExibicao: dataExibicao, exibeMensagem: false, opcAgrupamento: opcAgrupamento });
       this.montaTabela(dadosFaturamentoFiltrado, opcAgrupamento);
     }.bind(this));
     */
  }
  //
  render() {
    const agrupamentosOpc = [
      { key: 'dia', value: 'dia', text: 'Dia' },
      { key: 'fabrica', value: 'filial', text: 'Fábrica' },
      { key: 'produto', value: 'produto', text: 'Produto' },
      { key: 'estado', value: 'estado', text: 'Estado' }
    ];
    return(
      <div>
        <Grid textAlign = 'center' style = {{ height: '100%' }} verticalAlign = 'top'>
          <Grid.Row style = {{ padding: '0px', width:'350px' }}>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'50px' }}></Grid.Column>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'250px'}}>
              <Header as = 'h5' style = {{ marginTop: '0px', textAlign: 'center' }}>
                { this.props.nomeFabrica } <br /> { this.props.tituloTela }
              </Header>
            </Grid.Column>
            <Grid.Column style = {{ paddingLeft:'0px',paddingRight:'0px', width:'50px' }}>
              <Icon name = 'refresh' style = {{ paddingRight:'25px' }} onClick = { this.atualizaParaDataAtual } />
              <Icon name = 'window close' onClick = { this.voltarTelaAnterior }/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{ width:'350px', height: '65px', padding: '0px' }}>
            <Grid.Column style={{textAlign: 'left', paddingLeft:'0px', paddingRight:'0px', width:'116px'}}>
              <Segment basic>
                <Button icon onClick = { this.alteraDataExibicao.bind(this, 'diminuir') }>
                  <Icon name='angle left' attached='left'/>
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{ textAlign: 'center', paddingLeft:'0px',paddingRight:'0px', width:'116px'}}>
              <Segment basic >
                <Label size="big" style={{height:'40px', fontSize:'11px', padding:'15px'}}>
                  {this.state.dataExibicao}
                </Label>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{textAlign: 'right', paddingLeft:'0px',paddingRight:'0px', width:'116px'}}>
              <Segment basic>
                <Button icon onClick = { this.alteraDataExibicao.bind(this, 'adicionar') }>
                  <Icon name='angle right' />
                </Button>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{ textAlign: 'center', padding:'0px', fontSize:'12px', width:'350px' }}>
            <Grid columns ='equal'>
              <Grid.Column style={{paddingLeft:'0px',paddingRight:'5px', width:'175px'}}>
                <Card style={{height: '75px'}}>
                  <Card.Content>
                    <Card.Header style={{marginTop: '0px'}}>FATURAMENTO</Card.Header>
                    <Card.Meta style={{marginTop: '0px'}}>Acumulado</Card.Meta>
                    <Card.Description style={{fontSize:'15px', fontWeight: 'bold', marginTop: '0px'}}>{this.formataValores(this.state.faturamentoAcumulado)}</Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column style={{paddingLeft:'5px',paddingRight:'0px', width:'175px'}}>
                <Card style={{height: '75px'}}>
                  <Card.Content>
                    <Card.Header style={{marginTop: '0px'}}>PACOTES/CAIXAS</Card.Header>
                    <Card.Meta style={{marginTop: '0px'}}>Acumulado</Card.Meta>
                    <Card.Description style={{fontSize:'15px', fontWeight: 'bold', marginTop: '0px'}}>{this.formataValores(this.state.pacotesCaixasAcumulado)}</Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid>
          </Grid.Row>
          { this.state.DadosFaturamentoFiltrado.length === 0 && this.state.exibeMensagem === false &&
              <Message style = {{ textAlign: 'center', width:'350px', fontSize: '14px' }}>
                Sem dados lançados para este período 
              </Message>
          }
          { this.state.exibeMensagem  &&
              <Message style = {{ textAlign: 'center', width:'350px', fontSize: '14px' }}>
                {this.state.mensagem}
              </Message>
          }
          { this.state.ConteudoTabela.length > 0 &&
          <div>
          <Grid.Row style= {{ paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px" }}>
            <span style = {{ fontSize: '14px' }}>Agrupado por{' '}
                <Dropdown inline options = { agrupamentosOpc } name = "opcAgrupamento" value = {this.state.opcAgrupamento} 
                                 onChange = { this.alteraAgrupador }/>
              </span>
          </Grid.Row>
          <Grid.Row >
            <Table className="ui striped table" unstackable style={{ padding:'0px', width:'350px'}} >
              <Table.Header style={{display: 'block', fontSize:'12px',  width:'350px'}}>
                <Table.Row style = {{ textAlign: 'center',  width:'350px' }}>
                  <Table.HeaderCell style = {{ height: '45px', width:'220px', padding:'0px', textAlign: 'center' }}> 
                    {this.state.opcAgrupamento.toUpperCase() === "FILIAL" ? " FÁBRICA" : this.state.opcAgrupamento.toUpperCase()}
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'60px', padding:'0px', textAlign: 'center' }}>
                    PCT/CX
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'60px', padding:'0px', textAlign: 'center'}} >
                    R$
                  </Table.HeaderCell>
                  <Table.HeaderCell style = {{ width:'10px', padding:'0px', textAlign: 'center' }}>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style = {{ fontSize:'10px', display: 'block', height: '360px', width:'350px', overflow: 'auto'}}>
                {this.state.ConteudoTabela}
              </Table.Body>
            </Table>
          </Grid.Row>
          </div>
          }
        </Grid>
        {this.state.loader && <Loader active />}
      </div>
    )
  }
}
export default ConsolidadoFaturamento