export const tokenChave = "@Token";
export const codUsuario = "@Cod";
export const validaAutenticaocao = () => localStorage.getItem(tokenChave) !== null;
export const getToken = () => localStorage.getItem(tokenChave);
export const getCodUsuario = () => localStorage.getItem(codUsuario);
export const versao = "v20200701.001";

export const salvaToken = token => {
    localStorage.setItem(tokenChave, token);
};
export const salvaCodUsuario = usuario => {
    localStorage.setItem(codUsuario, usuario);
};
export const removeToken = () => {
    localStorage.removeItem(tokenChave);
    localStorage.removeItem(codUsuario);
};